@import "style/theme/light";
@import "style/index";

.container {
  display: grid;
  grid-template-columns: 50px 200px 4fr minmax(350px, 1fr);
  grid-template-rows: 100%;
  grid-gap: 5px;
  height: calc(100vh - 95px);
  background-color: $background-color;
  @media only screen and (max-width: 900px) and (orientation: portrait) {
    grid-template-columns: 50px 200px auto 25px;
  }
}
