$error-color: red;
$main-font-size: 12px;
$navbar-height: 50px;
$tool-bar-width: 45px;
$foreground-color: #fff;
$menu-collapse-time: 300ms;
$tool-container-height: 35px;
$border-radius: 0.1rem;

$mui-row-hover: #eef3f9;
$mui-primary-color: #1871e8;
$mui-primary-button-hover: #0d62d2;
$mui-disabled-color: #d5dfe9;
$mui-disabled-text-color: #9ca8b4;
$mui-list-item-hover: #f2f5f9;
$mui-border-dropdown-button: #6c757d;
$mui-border-dropdown-button-focus: #545b62;
