@import "style/index";
@import "style/theme/light.scss";

.container {
  width: 210px;
  font-size: 12px;
  border: 1px solid #d7d7d7;
  margin-left: 10px;
  border-radius: 0.25rem;
}
.label {
  font-size: 16px;
  color: white;
}
