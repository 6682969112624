.tourGuideTooltip {
  min-width: 15rem;
  max-width: 28rem;
}

.tourGuideFooter {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}
